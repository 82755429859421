<div class="startpage">
    <div class="introtext">
        Solve all the days to get the coordinates to the physical container.
    </div>

    <div class="startgame" v-if="!loading">
        <div class="buttons">
<!--            <button @click="startGame(1)">Play with ambient music</button>-->
            <button @click="startGame(0)">Start the game</button>
        </div>
<!--        <p>You can toggle the music at any time</p>-->
    </div>
    <div v-else>
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div v-if="message" v-html="message"></div>
</div>
