import { createStore } from 'vuex'

import inventory from "@/store/modules/inventory";
import api from "@/store/modules/api";
import localstorage from "@/store/modules/localstorage";
import itemmodal from "@/store/modules/itemmodal";

export default createStore({
    modules: { inventory, api, localstorage, itemmodal },
    state: {},
    getters: {},
    mutations: {},
    actions: {},
})
