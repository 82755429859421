<template src="./ResetProgress.html"/>

<script>
export default {
  name: "ResetProgress",
  data() {
    return {}
  },
  methods: {
    reset() {
      this.$store.dispatch('reset');
    },
    cancel() {
      window.location.hash = '/game';
    }
  }
}
</script>

<style scoped lang="scss">
@import 'ResetProgress.scss';
</style>
