<template>
    <HeaderComponent/>
    <div class="game-area">
        <transition name="route" mode="out-in">
            <component :is="currentView"/>
        </transition>
    </div>
    <AudioPlayer/>
</template>

<script>

import StartPage from "@/components/Routing/StartPage/StartPage.vue";
import ResetProgress from "@/components/Routing/ResetProgress/ResetProgress.vue";
import NotFound from "@/components/Routing/NotFound/NotFound.vue";
import GameView from "@/components/Routing/GameView/GameView.vue";
import LoadingView from "@/components/LoadingView.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import AudioPlayer from '@/components/AudioPlayer.vue';

const routes = {
  '/': LoadingView,
  '/start': StartPage,
  '/reset': ResetProgress,
  '/game': GameView
};
export default {
  name: 'App',
  components: {
    AudioPlayer,
    HeaderComponent,
    StartPage,
    ResetProgress,
    NotFound,
    GameView
  },
  data() {
    return {
      currentPath: window.location.hash
    };
  },
  computed: {
    apiStatus() {
      return this.$store.getters.apiStatus;
    },
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || NotFound;
    }
  },
  mounted() {
    this.$store.dispatch('init');
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash;
    });
  },
  watch: {
    apiStatus(newVal) {
      if (newVal === 'inprogress') {
        //Game is running
        window.location.hash = '/game';
      } else if (newVal === 'ready') {
        window.location.hash = '/start';
      } else if (newVal === 'loading') {
        window.location.hash = '/';
      }
    }
  }
};
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  background: #3d3d3d;
  font-size: 18px;
  line-height: 22px;
}

#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 20px;
  color: #fff;

  overflow: hidden;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  min-height: 100vh;
  min-height: -webit-fill-availible;

  box-sizing: border-box;
  gap: 20px;
}

.route-enter-active {
  transition: 0.2s;
}

.route-leave-active {
  transition: 0.2s;
}

.route-enter-from,
.route-leave-to {
  transform: translateY(20px) scale(0.7);
  opacity: 0;
}

.fade-enter-active {
  transition: 0.2s;
}

.fade-leave-active {
  transition: 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(20px) scale(0.7);
  opacity: 0;
}
</style>
