<template src="./InventoryItem.html"/>

<script>
export default {
  name: "InventoryItem",
  props: {
    id: Number,
    title: String,
    viewed: Boolean,
    type: String,
    content: String,
    icon: String,
    unviewed: Boolean
  },
  data() {
    return {}
  },
  computed: {
    decodedContent() {
      return atob(this.content);
    },
    backgroundImage() {
      return 'background-image: url("'+this.imageUrl+'")';
    },
    imageUrl() {
      return process.env.VUE_APP_API+this.content;
    }
  },
  methods: {
    viewItem() {
      this.$store.dispatch('itemModal', this.id);
    }
  }
}
</script>

<style scoped lang="scss">
@import 'InventoryItem.scss';
</style>
