<template>
  <div class="ambient-audio">
    <button @click="playPause">{{ buttonTitle }}</button>
    <audio @ended="audioEnded" :ref="`ambient`" autoplay="false" preload="none">
    </audio>
    <!--    <audio-->
    <!--        v-for="(audio, index) in audioFiles" preload="none" autoplay="false" :key="index" :id="`audio`+index"-->
    <!--        @ended="audioEnded(index)" :ref="`audio`+index">-->
    <!--      <source :src="audio" type="audio/ogg">-->
    <!--    </audio>-->
  </div>
</template>

<script>
export default {
  name: "AudioPlayer",
  data() {
    return {
      playing: false,
      current: -1,
      audioFiles: [
        '/audio/christmas_miracle.ogg',
        '/audio/holiday_hustle.ogg',
        '/audio/presents_on_christmas_morning.ogg',

      ]
    }
  },
  computed: {
    playAudio() {
      return this.$store.getters.playAudio;
    },
    buttonTitle() {
      if (this.playing) {
        return 'Pause music'
      } else if (!this.playing) {
        return 'Play music';
      }
      return '';
    }
  },
  methods: {
    playNext() {
        this.playing = true;
        this.current = this.random(this.current);
        this.$refs['ambient'].src = this.audioFiles[this.current];
        this.$refs['ambient'].play();
    },
    playPause() {
      if (this.playing) {
        this.$refs['ambient'].pause();
        this.playing = false;
      } else {
        this.playing = true;
        if (this.current > -1) {
          this.$refs['ambient'].play();
        } else {
          this.playNext();
        }
      }
    },
    random(i) {
      let next = i;
      while (next === i)
        next = Math.floor(Math.random() * this.audioFiles.length);
      return next;
    },
    audioEnded() {
      this.playNext();
    }
  },
  watch: {
    playAudio(newVal) {
      if (parseInt(newVal) === 1) {
        this.playNext();
      }
    }
  },
  mounted() {
    this.$refs['ambient'].volume = 0.5;
    if (this.playAudio) {
      this.playNext();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
