const state = () => ({
    audio: 0
});
const getters = {
    playAudio(state) {
        return state.audio;
    }
};
const mutations = {
    setAudio(state, audio) {
        state.audio = audio;
    }
};
const actions = {
    reset({commit}) {
        commit('setAudio', 0);
        commit('setJWT', null);
        commit('setInventory', {});
    },
    init({commit}) {
        //Always set audio to 0 since browser might prevent autoplay
        commit('setAudio', 0);

        //Check for existing jwt
        const jwt = localStorage.getItem('jwt') || null;
        if (jwt) {
            commit('setJWT', jwt);
            const inventory = JSON.parse(localStorage.getItem('inventory')) || {};
            commit('setInventory', inventory);
        }
        else {
            commit('setApiReady');
        }
    },
};


export default {
    state,
    getters,
    actions,
    mutations
}
