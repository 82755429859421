<div class="hintsystem" :class="{active: hintVisible}">
    <div class="loading" :class="{active: isLoading}">
        <div class="rotation"></div>
    </div>
    <div class="content">
        <div><button @click="closeHint">Close</button></div>
        <h2>Hints</h2>
        <p>None of the hints will give the answer, but the last hint may give the solution.</p>
        <button @click="loadHints">Fetch hints</button>
        <div class="hints">
            <div v-for="(dayData, dayIndex) in hints" class="hint-buttons">
                <h3>{{dayIndex}}</h3>
                <div class="hint-buttons-container">
                    <button v-for="(hint, hintIndex) in dayData" @click="showHint(dayIndex, hintIndex)">Hint {{ hintIndex+1 }}</button>
                </div>
            </div>
        </div>
        <p class="current-hint" v-html="currentHint"></p>
    </div>
</div>
