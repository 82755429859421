<template src="./NotFound.html"/>

<script>
export default {
  name: "NotFound",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
@import 'NotFound.scss';
</style>
