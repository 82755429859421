const state = () => ({
    modalData: {},
    showItemModal: false,
    hintVisible: false
});
const getters = {
    itemModalData(state) {
        return state.modalData;
    },
    showItemModal(state) {
        return state.showItemModal;
    },
    hintVisible(state) {
        return state.hintVisible;
    }
};
const mutations = {
    setModalData(state, modalData) {
        state.modalData = modalData;
        state.showItemModal = true;
    }
};
const actions = {
    itemModal({commit, getters, state}, id) {
        if (id) {
            commit('setModalData', getters.getItem(id));
            commit('setViewed', id);
        }
        else {
            state.showItemModal = false;
        }
    },
    toggleHint({state}) {
        state.hintVisible = !state.hintVisible;
    }
};


export default {
    state,
    getters,
    actions,
    mutations
}
