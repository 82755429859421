<template>
    <div class="blackout" :class="{active: isActive}" @click.self="hideModal">
        <div class="item-modal" :class="{active: isActive}">
            <div>
                <button @click="hideModal">Close</button>
            </div>
            <h1>{{ itemTitle }}</h1>
            <div class="image" v-if="itemType === 'image'">
                <img :src="getUrl(itemContent)" :alt="itemTitle" @click="openImage"/>
                <p>(Click image to open fullsize)</p>
            </div>
            <div class="letter" v-else-if="itemType === 'letter'" v-html="decodeString(itemContent)">
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ItemModal",
  data() {
    return {};
  },
  methods: {
    getUrl(path) {
      return process.env.VUE_APP_API + path;
    },
    decodeString(theString) {
      return atob(theString);
    },
    hideModal() {
      this.$store.dispatch('itemModal', null);
    },
    openImage() {
      let theUrl;
      if (this.itemContent.substr(0, 4) === 'data') {
        const base64ImageData = this.itemContent;
        const contentType = 'image/png';
        const basedata = base64ImageData.substr(`data:${contentType};base64,`.length);
        const byteCharacters = atob(basedata);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        const blobUrl = URL.createObjectURL(blob);
        theUrl = blobUrl;
      } else {
        theUrl = this.itemContent;
      }
      window.open(this.getUrl(theUrl), '_blank');
    }
  },
  computed: {
    isActive() {
      return this.$store.getters.showItemModal;
    },
    itemTitle() {
      return this.modalData.title;
    },
    itemContent() {
      return this.modalData.content;
    },
    itemType() {
      return this.modalData.type;
    },
    modalData() {
      return this.$store.getters.itemModalData;
    }
  },
  mounted() {
    document.addEventListener('keydown', (evt) => {
      if (evt.code === "Escape") {
        this.hideModal();
      }
    });
  }
};
</script>

<style scoped lang="scss">
.blackout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: 0.2s;
  z-index: 10;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .item-modal {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 75vw;
    min-width: 320px;
    max-width: 800px;

    background: #fff;
    transition: 0.2s;
    padding: 15px;
    color: #000;
    box-sizing: border-box;
    transform: translateX(105%);
    overflow: auto;

    @media all and (max-width: 319px) {
      min-width: 100%;
      left: 0;
    }

    &.active {
      transform: translateX(0);
    }

    .letter {
      max-width: 400px;
      margin: 0 auto;
      overflow-wrap: anywhere;
      text-align: left;
    }

    .image {
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;

        & + p {
          padding: 5px 0 0 0;
          margin: 0;
          font-style: italic;
          font-size: 90%;
        }
      }
    }
  }
}

.opacityfade-enter-active {
  transition: 0.2s;
}

.opacityfade-leave-active {
  transition: 0.2s;
}

.opacityfade-enter-from,
.opacityfade-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>
