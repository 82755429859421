<template src="./HintSystem.html"/>

<script>
export default {
  name: "HintSystem",
  data() {
    return {
      isLoading: false,
      currentHint: '',
      hints: {}
    };
  },
  computed: {
    hintVisible() {
      return this.$store.getters.hintVisible;
    }
  },
  methods: {
    closeHint() {
      this.$store.dispatch('toggleHint');
    },
    showHint(dayIndex, hintIndex) {
      this.currentHint = this.hints[dayIndex][hintIndex]
    },
    async loadHints() {
      this.isLoading = true;
      const hints = await this.$store.dispatch('getHint');
      this.hints = {};
      for (const day in hints) {
        this.hints[day] = hints[day];
      }
      this.isLoading = false;
    }
  },
  mounted() {
    document.addEventListener('keydown', (evt) => {
      if (evt.code === "Escape" && this.hintVisible) {
        this.$store.dispatch('toggleHint');
      }
    });
  }
};
</script>

<style scoped lang="scss">
@import 'HintSystem.scss';
</style>
