const state = () => ({
    inventory: {}
});
const getters = {
    inventory(state) {
        //Weird usage... lodash just would not work
        let sortableArray = [];
        for (const key in state.inventory) {
            sortableArray.push({ ...state.inventory[key] });
        }
        sortableArray.sort((a, b) => {
            let fa = a.title.toLowerCase(),
              fb = b.title.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        return sortableArray;
    },
    getItem: state => (id) => {
        return state.inventory[id];
    }
};
const mutations = {
    setInventory(state, inventory) {
        state.inventory = inventory;
        localStorage.setItem('inventory', JSON.stringify(inventory));
    },
    setViewed(state, id) {
        if (id in state.inventory) {
            state.inventory[id].viewed = true;
            this.commit('storeInventory');
        }
    },
    addItem(state, item) {
        state.inventory[item.id] = item;
        this.commit('storeInventory');
    },
    storeInventory(state) {
        localStorage.setItem('inventory', JSON.stringify(state.inventory));
    }
};
const actions = {
    clearNewItems({state}) {
        for (const id in state.inventory) {
            state.inventory[id].viewed = true;
        }
    },
    addInventory({commit}, itemsToAdd) {
        for (let idx in itemsToAdd) {
            if (itemsToAdd[idx]) {
                itemsToAdd[idx]['viewed'] = false;
                commit('addItem', itemsToAdd[idx]);
            }
        }
    },
    removeInventory({state, commit}, itemsToRemove) {
        for (let idx in itemsToRemove) {
            delete state.inventory[itemsToRemove[idx]];
        }
        commit('storeInventory');
    },
};


export default {
    state,
    getters,
    actions,
    mutations
}
