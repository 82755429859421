<template>
  <div class="inventorylist-wrapper">
  <h2>Inventory</h2>
    <p>(click to show larger version)</p>
    <transition-group tag="div" name="fade" class="inventorylist">
      <InventoryItem v-for="item in inventory" :key="item.id" v-bind="item"/>
    </transition-group>

  </div>
</template>

<script>
import InventoryItem from "@/components/InventoryItem/InventoryItem.vue";

export default {
  name: "InventoryList",
  components: { InventoryItem },
  computed: {
    inventory() {
      return this.$store.getters.inventory;
    }
  }
}
</script>

<style scoped lang="scss">
.inventorylist {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  @media screen and (max-width: 1500px){
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 900px){
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 800px){
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 550px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 400px){
    grid-template-columns: repeat(1, 1fr);
  }

}
p,
h2 {
  padding: 0;
  margin: 10px 0;
}
</style>
