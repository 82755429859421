<template src="./GameView.html"/>

<script>

import InventoryList from "@/components/InventoryList.vue";
import SolutionInput from '@/components/SolutionInput.vue';
import ItemModal from '@/components/ItemModal.vue';
import HintSystem from '@/components/HintSystem/HintSystem.vue';

export default {
  name: "GameView",
  components: { HintSystem, SolutionInput, InventoryList, ItemModal },
  data() {
    return {
      enterPassword: false
    };
  },
  methods: {
    resetGame() {
      window.location.hash = '/reset';
    }
  }
};
</script>

<style scoped lang="scss">
@import 'GameView.scss';
</style>
