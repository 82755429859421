<template>
  <div class="header">
    <div class="image">
      <img src="./../assets/fatherchristmas.svg" alt="{{ process.env.VUE_APP_TITLE }}" loading="lazy"/>
    </div>
    <div class="text">
      <h1>Advent 2023</h1>
      <p><a :href="gcWebsite" target="_blank">{{ gcWebsite }}</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  computed: {
    gcWebsite() {
      return 'https://coord.info/' + process.env.VUE_APP_GCCODE;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  max-width: 400px;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
}
img {
  max-width: 120px;
  height: auto;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>
